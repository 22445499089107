import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VTextField,{ref:"zipCodeField",attrs:{"placeholder":"75001","label":"Code postal","rules":[_vm.rules.mandatory]},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}}),_c(VBtn,{attrs:{"small":"","icon":"","elevation":"1"},on:{"click":function($event){return _vm.fetchSuggestions(_vm.zipCode)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("search")])],1)],1)]),_c(VCol,{attrs:{"cols":"12","sm":"9"}},[_c(VAutocomplete,{attrs:{"disabled":_vm.selected === null,"placeholder":"Sélectionner une agence","items":_vm.suggestions,"item-text":"name","loading":_vm.loading,"return-object":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }