<template>
  <v-app>
    <v-app-bar dark app color="green darken-3" v-resize="onResize">
      <v-toolbar-title class="headline text-uppercase">
        <span class="hidden-sm-only">Yodaforex&nbsp;</span>
        <span class="hidden-md-and-up hidden-xs-only">Yfx&nbsp;</span>
        <span>License</span>
      </v-toolbar-title>
      <v-spacer/>

      <v-tabs v-if="!showTabsSeparately" v-model="tab" fixed-tabs style="max-width: 400px" class="mr-4">
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="item in availableTabs" :key="item.path">{{ item.text }}</v-tab>
      </v-tabs>

      <y-auth-button v-model="isAuth" success-color="light-green accent-2" error-color="deep-orange"/>

      <template v-if="showTabsSeparately" v-slot:extension>
        <v-tabs centered v-model="tab" mobile-breakpoint="300" center-active>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in availableTabs" :key="item.path">{{ item.text }}</v-tab>
        </v-tabs>
      </template>

    </v-app-bar>

    <v-main>
      <router-view v-if="isAuth"/>
      <v-container v-else>
        <div class="d-flex justify-center">
          <v-progress-linear indeterminate/>
        </div>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      showTabsSeparately: false,
      isAuth: false,
      tab: 0,
      availableTabs: [
        {text: 'Licences', path: '/'},
        // {text: 'Versions', path: '/versions'},
        {text: 'Facturation', path: '/invoicing'},
      ],
      isInit: false
    }
  },
  watch: {
    tab: {
      immediate: false,
      handler: function (newTab) {
        this.$router.push(this.availableTabs[newTab].path);
      }
    }
  },
  created() {
    this.onResize()
    this.tab = this.getTabAccordingToCurrentRoute();
  },
  mounted() {
    this.isInit = true;
  },
  methods: {
    onResize() {
      this.showTabsSeparately = window.innerWidth < 600;
    },
    getTabAccordingToCurrentRoute() {
      switch (this.$router.currentRoute.name) {
        case 'Branch':
        case 'Home':
          return 0;
        case 'Invoicing':
          return 1;
        default:
          return 0;
      }
    }
  },
};
</script>

