import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.error)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v("Une erreur est survenue."),(_vm.errorCode)?_c('span',[_vm._v(" "+_vm._s(_vm.errorCodeToText(_vm.errorCode)))]):_vm._e()]):_vm._e(),(_vm.report == null || _vm.loading === true)?_c('div',[_c(VBtn,{attrs:{"loading":_vm.loading,"block":"","rounded":"","color":"green darken-1","dark":""},on:{"click":_vm.fetch}},[_vm._v("Lancer le calcul")])],1):(_vm.error === false)?_c('div',[_c('ul',[_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.report.axonautOrganisationUrl}},[_vm._v("Voir l'organisation Axonaut de "+_vm._s(_vm.branch.name))])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.report.axonautMainContractUrl}},[_vm._v("Voir le contrat socle utilisé")])])]),_vm._l((_vm.report.inconsistencies),function(inconsistency,idx){return _c(VRow,{key:idx,staticClass:"mt-4"},[_c(VCol,[_c('invoicing-inconsistency-report-line',{attrs:{"inconsistency":inconsistency}})],1)],1)}),(_vm.report.inconsistencies && _vm.report.inconsistencies.length === 0)?_c(VRow,[_c(VAlert,{staticClass:"mt-6",staticStyle:{"width":"100%"},attrs:{"type":"success"}},[_vm._v(" Aucune incohérence n'a été détectée ")])],1):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }