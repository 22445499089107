import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.unknownError)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v("Une erreur est survenue. Cette société a-t-elle un cloud ?")]):(_vm.noCloudError)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("Cette société ne semble pas avoir de cloud, ou sa configuration n'est identique entre toutes ses agences. ")]):(!_vm.result)?_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"loading":_vm.loading,"block":"","rounded":"","color":"green darken-1","dark":""},on:{"click":_vm.fetch}},[_vm._v("Lancer le calcul")])],1)],1):_c(VRow,[_c(VCol,[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Table")]),_c('th',{staticClass:"text-left"},[_vm._v("Nb. enreg.")])])]),_c('tbody',_vm._l((_vm.result),function(v,t){return _c('tr',{key:t},[_c('td',[_c('pre',[_vm._v(_vm._s(t))])]),_c('td',[_c('pre',[_vm._v(_vm._s(v))])])])}),0),_c('tfoot',[_c('tr',{staticClass:"blue-grey lighten-3"},[_c('th',[_vm._v("Total")]),_c('td',[_c('pre',[_vm._v(_vm._s(_vm.total))])])]),_c('tr',{staticClass:"lime lighten-3"},[_c('th',[_vm._v("Total à facturer")]),_c('td',[_c('pre',[_vm._v(_vm._s(_vm.totalToBill))])])]),_c('tr',{staticClass:"lime lighten-4"},[_c('th',[_vm._v("Nb. de pack de 100 000 lignes à facturer")]),_c('td',[_c('pre',[_vm._v(_vm._s(_vm.totalOfProductLignesToBill))])])])])]},proxy:true}])})],1)],1),_c(VRow,[_c(VCol,[_c('cloud-consult-reset-quota-button',{attrs:{"company-uuid":_vm.company.uuid}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }