import axios from "axios";
import Config from "@/config";

export default {

	_subscribersForCompanies: [],
	_subscribersForBranches: [],

	_statusForCompanies: {},
	_statusForBranches: {},

	_hasFetched: false,
	_isFetching: false,

	getStatusForCompany(companyUuid) {
		return new Promise((resolve, reject) => {
			if (!this._hasFetched) {
				this._subscribersForCompanies.push({companyUuid, resolve, reject});
				if (!this.isFetching) {
					this._fetch().finally(() => this._warnSubscribers());
				}
			} else {
				resolve(this._statusForCompanies[companyUuid] ? this._statusForCompanies[companyUuid] : []);
			}
		})
	},
	getStatusForBranch(branchUuid) {
		return new Promise((resolve, reject) => {
			if (!this._hasFetched) {
				this._subscribersForBranches.push({branchUuid, resolve, reject});
				if (!this.isFetching) {
					this._fetch().finally(() => this._warnSubscribers());
				}
			} else resolve(this._statusForBranches[branchUuid] ? this._statusForBranches[branchUuid] : []);
		})
	},

	_warnSubscribers() {
		for (const sub of this._subscribersForBranches) {
			sub.resolve(this._statusForBranches[sub.branchUuid] ? this._statusForBranches[sub.branchUuid] : []);
		}
		for (const sub of this._subscribersForCompanies) {
			sub.resolve(this._statusForCompanies[sub.companyUuid] ? this._statusForCompanies[sub.companyUuid] : []);
		}
	},

	_fetch() {
		this.isFetching = true;
		return new Promise((resolve, reject) => {
			axios.get(Config.services.computerParkApi.url + "/v1/summary")
				.then(r => {
					this._statusForCompanies = r.data.companies;
					this._statusForBranches = r.data.branches;
					resolve();
				})
				.catch((e) => reject(e))
				.finally(() => this._hasFetched = true);
		})
	}
}

