import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('branch-selected', _vm.branch)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.branch.companyName)+" - "+_vm._s(_vm.branch.name)+" ("+_vm._s(_vm.branch.shopName)+") "),(_vm.branch.yodatechName)?_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.branch.yodatechName))]):_vm._e()]),_c(VListItemSubtitle,[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{},[_c('pre',[_vm._v(_vm._s(_vm.branch.uuid))]),_c('pre',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.branch.yodaforexVersion))])]),_vm._l((_vm.customFieldsToShow),function(field,i){return _c(VCol,{key:i,attrs:{"cols":"auto"}},[_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(field))]),_vm._v("→"),_c('span',[_vm._v(_vm._s(_vm.branch.license.conf[field]))])])})],2)],1)],1),_c(VListItemSubtitle,[(!_vm.showActivationStatus)?_c('div',{staticClass:"d-inline"},[_c('span',{staticClass:"caption"},[_vm._v("Licence active ")]),_c(VIcon,{attrs:{"small":"","color":_vm._f("colorForStatus")(_vm.branch.licenseActivationStatus),"aria-hidden":"false"}},[_vm._v(_vm._s(_vm._f("iconForStatus")(_vm.branch.licenseActivationStatus)))]),_c('span',{staticClass:"caption"},[_vm._v(" — ")])],1):_vm._e(),_c('span',{staticClass:"caption"},[_vm._v("Support actif ")]),_c(VIcon,{attrs:{"small":"","color":_vm._f("colorForStatus")(_vm.branch.licenseSupportStatus),"aria-hidden":"false"}},[_vm._v(_vm._s(_vm._f("iconForStatus")(_vm.branch.licenseSupportStatus)))]),_c('span',[_vm._v(" — Cloud ")]),_c(VIcon,{attrs:{"small":"","color":_vm.branch.license.conf['cloud.actif'] === 'true' ? 'light-blue' : 'grey lighten-1'}},[_vm._v("mdi-cloud")]),_c('span',[_vm._v(" — EA ")]),_c(VIcon,{attrs:{"small":"","color":_vm.branch.license.conf['ea.actif'] === 'true' ? 'amber' : 'grey lighten-1'}},[_vm._v("mdi-star")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }