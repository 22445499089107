import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,[_c(VCardText,[_c(VTreeview,{attrs:{"items":_vm.tree,"dense":"","open-all":"","open-on-click":""},scopedSlots:_vm._u([{key:"label",fn:function({item}){return [_c('span',[_vm._v(_vm._s(item.version ? item.version : "Répartition actuelle des versions"))]),_c(VChip,{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(_vm._s(item.count))])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }