module.exports = {
  env: 'production',
  apiUrl: '',
  authConfig: {},
  services: {
    consultApi: {
      url: 'https://consult.api.yodaforex.cloud',
    },
    computerParkApi: {
      url: 'https://genepi.api.yodaforex.cloud',
    }
  }
}
