import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.confKeyObject.type === 'string')?_c(VTextField,{attrs:{"outlined":"","label":_vm.label},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}}):_vm._e(),(_vm.confKeyObject.type === 'email')?_c(VTextField,{attrs:{"outlined":"","confKeyObject.type":"email","label":_vm.label},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}}):(_vm.confKeyObject.type === 'number')?_c(VTextField,{attrs:{"outlined":"","confKeyObject.type":"number","label":_vm.label},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}}):(_vm.confKeyObject.type === 'boolean')?_c('div',[_c(VSwitch,{attrs:{"label":_vm.label + (_vm.label ? ' - ' : '') + (_vm.userVal ? 'vrai' : 'faux')},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}})],1):(_vm.confKeyObject.type === 'enum')?_c(VAutocomplete,{attrs:{"outlined":"","items":_vm.confKeyObject.values,"label":_vm.label},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}}):(_vm.confKeyObject.type === 'date')?_c('div',[(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c(VDatePicker,{model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}})],1):(_vm.confKeyObject.type === 'time')?_c('div',[(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c(VTimePicker,{attrs:{"format":"24hr"},model:{value:(_vm.userVal),callback:function ($$v) {_vm.userVal=$$v},expression:"userVal"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }