import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"dark":"","app":"","color":"green darken-3"},scopedSlots:_vm._u([(_vm.showTabsSeparately)?{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"centered":"","mobile-breakpoint":"300","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"yellow"}}),_vm._l((_vm.availableTabs),function(item){return _c(VTab,{key:item.path},[_vm._v(_vm._s(item.text))])})],2)]},proxy:true}:null],null,true)},[_c(VToolbarTitle,{staticClass:"headline text-uppercase"},[_c('span',{staticClass:"hidden-sm-only"},[_vm._v("Yodaforex ")]),_c('span',{staticClass:"hidden-md-and-up hidden-xs-only"},[_vm._v("Yfx ")]),_c('span',[_vm._v("License")])]),_c(VSpacer),(!_vm.showTabsSeparately)?_c(VTabs,{staticClass:"mr-4",staticStyle:{"max-width":"400px"},attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"yellow"}}),_vm._l((_vm.availableTabs),function(item){return _c(VTab,{key:item.path},[_vm._v(_vm._s(item.text))])})],2):_vm._e(),_c('y-auth-button',{attrs:{"success-color":"light-green accent-2","error-color":"deep-orange"},model:{value:(_vm.isAuth),callback:function ($$v) {_vm.isAuth=$$v},expression:"isAuth"}})],1),_c(VMain,[(_vm.isAuth)?_c('router-view'):_c(VContainer,[_c('div',{staticClass:"d-flex justify-center"},[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }