import { render, staticRenderFns } from "./BranchTable.vue?vue&type=template&id=25592d49&scoped=true"
import script from "./BranchTable.vue?vue&type=script&lang=js"
export * from "./BranchTable.vue?vue&type=script&lang=js"
import style0 from "./BranchTable.vue?vue&type=style&index=0&id=25592d49&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25592d49",
  null
  
)

export default component.exports