<template>
  <v-list-item link @click="$emit('branch-selected', branch)">
    <v-list-item-content>
      <v-list-item-title>{{ branch.companyName }} - {{ branch.name }}
        ({{ branch.shopName }}) <span v-if="branch.yodatechName" class="text--disabled">{{ branch.yodatechName }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col class="">
              <pre>{{ branch.uuid }}</pre>
              <pre class="text--primary">{{ branch.yodaforexVersion }}</pre>
            </v-col>
            <v-col v-for="(field,i) in customFieldsToShow" :key="i" cols="auto">
              <span class="text--primary">{{ field }}</span>→<span>{{ branch.license.conf[field] }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-subtitle>

      <v-list-item-subtitle>
        <div class="d-inline" v-if="!showActivationStatus">
          <span class="caption">Licence active&nbsp;</span>
          <v-icon small :color="branch.licenseActivationStatus | colorForStatus" aria-hidden="false">{{ branch.licenseActivationStatus | iconForStatus }}</v-icon>
          <span class="caption">&nbsp;— </span>
        </div>
        <span class="caption">Support actif&nbsp;</span>
        <v-icon small :color="branch.licenseSupportStatus | colorForStatus" aria-hidden="false">{{ branch.licenseSupportStatus | iconForStatus }}</v-icon>
        <span>&nbsp;— Cloud&nbsp;</span>
        <v-icon small :color="branch.license.conf['cloud.actif'] === 'true' ? 'light-blue' : 'grey lighten-1'">mdi-cloud</v-icon>
        <span>&nbsp;— EA&nbsp;</span>
        <v-icon small :color="branch.license.conf['ea.actif'] === 'true' ? 'amber' : 'grey lighten-1'">mdi-star</v-icon>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import {LicenseBusiness} from "@/business/license-business";

export default {
  name: "ListItemBranch",
  props: ['branch', 'showActivationStatus', 'customFieldsToShow'],
  filters: {
    ...LicenseBusiness.filters
  },
}
</script>

<style scoped>

</style>
